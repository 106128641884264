"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";
import Icon from "@/components/icon";
function MobileNavMore({
  links
}) {
  const pathname = usePathname();
  return <div className={"bg-primary fixed grid grid-cols-2 grid-rows-5 gap-6 p-6 z-50 inset-0"} data-sentry-component="MobileNavMore" data-sentry-source-file="mobile-nav-more.tsx">
      {links.map(({
      href,
      label,
      icon
    }, index: number) => {
      return <div className={"flex"} key={index}>
            <Link key={icon} href={href} className={`${pathname === href ? " text-primary font-bold" : ""} flex bg-white flex-col justify-center rounded-2xl w-full items-center hover:text-primary`}
        // onClick={}
        >
              <Icon icon={icon} size={40}></Icon>
              <p className={"text-xl mt-1"}>{label}</p>
            </Link>
          </div>;
    })}
    </div>;
}
export default MobileNavMore;