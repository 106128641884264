"use client";

import { sendGTMEvent } from "@next/third-parties/google";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select2Option from "@/app/(main)/_public_components/select2-option";
import { getDepartments, getPublicContactTopics, postPublicContact } from "@/app/api/dynabuy-back/api-contact";
import Button from "@/components/button";
import ClientFlash from "@/components/global/client-flash";
import ComponentLoading from "@/components/global/component-loading";
import HocTextInput from "@/components/global/inputs/hoc-text-input";
import TextInput from "@/components/global/inputs/text-input";
import Icon from "@/components/icon";
import { callbackContext } from "@/context/callback-context-provider";
import useCitron from "@/hooks/use-citron";
import useSubmit from "@/hooks/use-submit";
import { DepartmentRead, GTMEventData } from "@/TS/Interfaces/global_interfaces";
import { handleErrors } from "@/utils/formFunc/form-func";
export function BlockForm() {
  const {
    register,
    reset,
    handleSubmit,
    control,
    getValues
  } = useForm();
  const {
    isAnalyticsAccepted
  } = useCitron();
  const {
    errors,
    loading,
    onSubmit,
    setVal
  } = useSubmit(postPublicContact, {
    callbackMessage: {
      type: "success2",
      message: "Votre demande a été envoyée avec succès !",
      position: "/#contact"
    },
    callback: () => {
      const formValues = getValues();
      const selectedTopicId = formValues.topic;
      const selectedTopic = listOptionsNature.find(option => option.value === selectedTopicId);
      if (isAnalyticsAccepted) {
        const eventData: GTMEventData = {
          event: "Contact",
          eventCategory: "Form",
          eventAction: "Submit",
          eventLabel: "Footer Contact Form",
          pageLocation: window.location.href,
          selectedTopicLabel: selectedTopic?.label
        };
        sendGTMEvent(eventData);
      }
      setSelect2Version(prev => prev + 1);
      reset();
    },
    errorFlashPath: "/#contact"
  });
  const contactRef = useRef(null);
  const EnhancedTextInput = HocTextInput(TextInput, loading, register);
  const transformSubmit = data => {
    data.phone = {
      countryCode: "+33",
      number: data.phone
    };
    return data;
  };
  const [contactTopics, setContactTopics] = useState(undefined);
  const [departments, setDepartments] = useState(undefined);
  const [select2Version, setSelect2Version] = useState(1);
  const {
    callback,
    setCallback,
    id,
    setNeedReload
  } = useContext(callbackContext);
  useEffect(() => {
    if (!callback || !contactRef.current || id !== "Contact") return;
    callback(contactRef);
    setCallback(null);
    return () => {
      setCallback(null);
    };
  }, [callback, contactRef.current]);
  useEffect(() => {
    const fetchLabelsForm = async () => {
      setContactTopics(await getPublicContactTopics());
      setDepartments(await getDepartments());
    };
    fetchLabelsForm();
  }, []);
  if (!contactTopics) return <ComponentLoading></ComponentLoading>;
  if (!departments) return <ComponentLoading></ComponentLoading>;
  const listOptionsDepartement = departments.map((department: DepartmentRead) => ({
    value: department["@id"],
    label: department.name
  }));
  const listOptionsNature = contactTopics.map(topic => ({
    value: topic["@id"],
    label: topic.label
  }));
  const placeholder = "Sélectionner une réponse";
  return <>
      <div className={"bg-primary py-8 text-white"} ref={contactRef}>
        <div className="container">
          <h2 className="header">
            Vous souhaitez obtenir des informations complémentaires sur les
            Clubs d’Affaires ?
          </h2>
          <div className="flex flex-col md:flex-row flex-wrap">
            <div className="w-full md:w-6/12 mb-8">
              <p className={"mb-4"}>
                <b> Contactez Dynabuy, </b> du lundi au vendredi de 8h45 à 12h30
                et de 13h50 à 17h30 :
              </p>
              <a href="tel:0251252011" className={"flex items-center w-fit mb-6"}>
                <Icon size={45} icon={"phone"} className={"bg-black rounded-full mr-3"} data-sentry-element="Icon" data-sentry-source-file="block-form.tsx" />
                <p>
                  <b> Téléphone : </b> <br /> 02 51 25 20 11
                </p>
              </a>
              <a href="mailto:clubsdaffaires@dynabuy.fr" className={"flex items-center w-fit"}>
                <Icon size={45} icon={"mail"} className={"bg-black rounded-full mr-3"} data-sentry-element="Icon" data-sentry-source-file="block-form.tsx" />
                <p>
                  <b> E-mail : </b> <br /> clubsdaffaires@dynabuy.fr
                </p>
              </a>
            </div>
            <div className="w-full md:w-6/12">
              <p className={"mb-4"}>
                <b> Ou laissez-nous vos coordonnées, </b> notre équipe vous
                rappelle dès que possible.
              </p>
              <div className={"bg-white rounded-2xl"}>
                <ClientFlash path={"/#contact"} data-sentry-element="ClientFlash" data-sentry-source-file="block-form.tsx" />
              </div>
              <form className={"flex flex-wrap"} onSubmit={handleSubmit(data => onSubmit(transformSubmit(data)))}>
                <EnhancedTextInput name={"firstName"} error={handleErrors(errors, "firstName")} errorsClassName={"bg-white rounded p-2"} label={"Prénom *"} needed={false} placeholder={"Saisir un prénom"} className={"bg-white"} containerClassName={"w-full lg:w-6/12 pr-0 lg:pr-4 mb-4"} requireClassname={"!text-white"} inputClassname={"text-dark"} data-sentry-element="EnhancedTextInput" data-sentry-source-file="block-form.tsx" />
                <EnhancedTextInput name={"lastName"} error={handleErrors(errors, "lastName")} errorsClassName={"bg-white rounded p-2"} label={"Nom *"} needed={false} placeholder={"Saisir un nom"} className={"bg-white"} containerClassName={"w-full lg:w-6/12 pl-0 lg:pl-4 mb-4"} requireClassname={"!text-white"} inputClassname={"text-dark"} data-sentry-element="EnhancedTextInput" data-sentry-source-file="block-form.tsx" />
                <EnhancedTextInput name={"jobTitle"} error={handleErrors(errors, "jobTitle")} errorsClassName={"bg-white rounded p-2"} label={"Fonction *"} needed={false} placeholder={"Saisir une réponse"} className={"bg-white"} containerClassName={"w-full lg:w-6/12 pr-0 lg:pr-4 mb-4"} requireClassname={"!text-white"} inputClassname={"text-dark"} data-sentry-element="EnhancedTextInput" data-sentry-source-file="block-form.tsx" />
                <EnhancedTextInput name={"companyName"} error={handleErrors(errors, "companyName")} errorsClassName={"bg-white rounded p-2"} label={"Société / Raison sociale *"} needed={false} placeholder={"Saisir un nom de société"} className={"bg-white"} containerClassName={"w-full lg:w-6/12 pl-0 lg:pl-4 mb-4"} requireClassname={"!text-white"} inputClassname={"text-dark"} data-sentry-element="EnhancedTextInput" data-sentry-source-file="block-form.tsx" />
                <EnhancedTextInput name={"phone"} error={handleErrors(errors, "phone")} errorsClassName={"bg-white rounded p-2"} label={"Numéro de téléphone *"} needed={false} placeholder={"Saisir un numéro de téléphone"} className={"bg-white"} containerClassName={"w-full lg:w-6/12 pr-0 lg:pr-4 mb-4"} requireClassname={"!text-white"} inputClassname={"text-dark"} data-sentry-element="EnhancedTextInput" data-sentry-source-file="block-form.tsx" />
                <EnhancedTextInput name={"email"} error={handleErrors(errors, "email")} errorsClassName={"bg-white rounded p-2"} label={"E-mail *"} needed={false} placeholder={"Saisir un e-mail"} className={"bg-white"} containerClassName={"w-full lg:w-6/12 pl-0 lg:pl-4 mb-4"} requireClassname={"!text-white"} inputClassname={"text-dark"} data-sentry-element="EnhancedTextInput" data-sentry-source-file="block-form.tsx" />
                <div className={"w-full lg:w-6/12 pr-0 lg:pr-4 mb-4"}>
                  <Controller control={control} render={({
                  field
                }) => <Select2Option {...field} error={handleErrors(errors, "department")} errorClassName={"bg-white rounded p-2"} isClearable={false} placeholder={placeholder} options={listOptionsDepartement} label={"Département souhaité *"} versionKey={select2Version} onChange={selectedOption => field.onChange(selectedOption.value)} />} name={"department"} data-sentry-element="Controller" data-sentry-source-file="block-form.tsx"></Controller>
                </div>
                <div className={"w-full lg:w-6/12 pl-0 lg:pl-4 mb-4"}>
                  <Controller control={control} render={({
                  field
                }) => <Select2Option error={handleErrors(errors, "nature")} errorClassName={"bg-white rounded p-2"} placeholder={placeholder} options={listOptionsNature} isClearable={false} label={"Nature de la demande *"} versionKey={select2Version} onChange={selectedOption => field.onChange(selectedOption.value)} />} name={"topic"} data-sentry-element="Controller" data-sentry-source-file="block-form.tsx"></Controller>
                </div>
                <p className={"w-full mb-4"}>* Champs obligatoires</p>
                <Button type={"submit"} className={"bg-dark w-4/12 mx-auto mb-8"} disabled={loading} data-sentry-element="Button" data-sentry-source-file="block-form.tsx">
                  Envoyer
                </Button>
              </form>
              <p className={"text-xs pb-14"}>
                Lorsque vous nous contactez, les informations que vous
                communiquez à DYNABUY ne sont utilisées qu’aux fins de gestion
                de votre demande, sur la base de l’intérêt légitime de DYNABUY à
                répondre aux demandes de contact qui nous sont adressées.
                DYNABUY ne conserve les données à caractère personnel traitées
                que pour la durée nécessaire aux opérations pour lesquelles
                elles ont été collectées ainsi que dans le respect de la
                réglementation en vigueur. Pour tout complément d’information,
                vous pouvez consulter notre page politique de confidentialité.
                Les droits d’accès, de rectification, d’opposition,
                d’effacement, à la portabilité, à la limitation du traitement
                peuvent être exercés par courriel à l’adresse dpo@dynabuy.fr
                DYNABUY apporte une grande attention aux données à caractère
                personnel qui lui sont confiées. Si vous considérez que le
                traitement des données vous concernant porte atteinte à vos
                droits, vous disposez de la faculté d’introduire une réclamation
                auprès de la CNIL.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>;
}