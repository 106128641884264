"use client";

import React, { useState } from "react";
import Select from "react-select";
import ErrorsInput from "@/components/global/inputs/errors-input";
function Select2Option({
  placeholder,
  options,
  isClearable = true,
  label,
  onChange,
  error,
  errorClassName,
  versionKey //used to reload the component
}) {
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [changed, setChanged] = useState(false);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "0.375rem",
      border: state.isFocused ? "2px solid #9ca3af" : "2px solid transparent",
      boxShadow: state.isFocused ? "0 0 5px rgba(255, 102, 0, 0.5)" : "none",
      "&:hover": {
        border: "2px solid #9ca3af"
      }
    }),
    option: (provided, state) => ({
      ...provided,
      color: "black",
      backgroundColor: state.isFocused ? "#f0f0f0" : "white",
      padding: 10
    }),
    singleValue: provided => ({
      ...provided,
      color: "black"
    }),
    placeholder: provided => ({
      ...provided,
      color: "#9B9B9B",
      fontStyle: "italic"
    })
  };
  const DropdownIndicator = props => {
    return <div className={"px-2"} data-sentry-component="DropdownIndicator" data-sentry-source-file="select2-option.tsx">
        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" fill="black" data-sentry-element="svg" data-sentry-source-file="select2-option.tsx">
          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" data-sentry-element="path" data-sentry-source-file="select2-option.tsx"></path>
        </svg>
      </div>;
  };
  const customComponents = {
    IndicatorSeparator: () => null,
    DropdownIndicator
  };
  return <>
      <label className={"text-md"}>{label}</label>
      <Select key={versionKey} className="basic-single" classNamePrefix={placeholder} onChange={selectedOption => {
      onChange(selectedOption);
      setChanged(true);
    }} defaultValue={placeholder} placeholder={placeholder} isDisabled={isDisabled} isLoading={isLoading} isClearable={isClearable} isRtl={isRtl} isSearchable={isSearchable} name="color" options={options} styles={customStyles} components={customComponents} data-sentry-element="Select" data-sentry-source-file="select2-option.tsx" />
      <ErrorsInput changed={changed} error={error} className={errorClassName} data-sentry-element="ErrorsInput" data-sentry-source-file="select2-option.tsx" />
    </>;
}
export default Select2Option;