"use client";

/**
 * @Author: JulienBigot7 julien@generation-internet.biz
 * @Date: 2024-10-08 11:50:39
 * @LastEditors: JulienBigot7 julien@generation-internet.biz
 * @LastEditTime: 2024-10-22 15:37:31
 * @FilePath: src/hooks/use-citron.tsx
 * @Description: 这是默认设置,可以在设置》工具》File Description中进行配置
 */
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
function useCitron(): {
  isMapAccepted: boolean;
  isAnalyticsAccepted: boolean;
} {
  const [isMapAccepted, setIsMapAccepted] = useState<boolean>(false);
  const [isAnalyticsAccepted, setIsAnalyticsAccepted] = useState<boolean>(false);
  const [cookies] = useCookies();
  useEffect(() => {
    if (!cookies) return;
    const cookie = cookies?.tarteaucitron;
    if (cookie) {
      const googleMapsAccepted = cookie.split("!").find(part => part.includes("googlemaps"))?.includes("true") || false;
      const analyticsAccepted = cookie.split("!").find(part => part.includes("gtag"))?.includes("true") || false;
      setIsMapAccepted(googleMapsAccepted);
      setIsAnalyticsAccepted(analyticsAccepted);
    }
  }, [cookies]);
  return {
    isMapAccepted,
    isAnalyticsAccepted
  };
}
export default useCitron;