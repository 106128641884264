"use client";

import React, { createContext, useState } from "react";
export const callbackContext = createContext(null);
function CallbackContextProvider({
  children
}) {
  const [callback, setCallback] = useState(null);
  const [id, setId] = useState(null);
  const [needReload, setNeedReload] = useState(false);
  return <callbackContext.Provider value={{
    callback,
    setCallback,
    id,
    setId,
    setNeedReload,
    needReload
  }} data-sentry-element="unknown" data-sentry-component="CallbackContextProvider" data-sentry-source-file="callback-context-provider.tsx">
      {children}
    </callbackContext.Provider>;
}
export default CallbackContextProvider;