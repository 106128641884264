import { getLead, getLeads } from "@/app/api/dynabuy-back/api-lead";
import {
  LeadReadOptions,
  LeadsSortFilter,
  NavLinks,
} from "@/TS/Interfaces/global_interfaces";

export const periodDates: Array<string> = ["2023", "2022", "2021", "2020"];

export const leadsSortFilter: Array<LeadsSortFilter> = [
  {
    callback: getLeads,
    label: "Du plus récent au plus ancien",
    value: "desc",
    params: { page: 1, orderBy: "DESC", orderAttr: "updatedAt" },
  },
  {
    callback: getLeads,
    label: "Du plus ancien au plus récent",
    value: "asc",
    params: { page: 1, orderBy: "ASC", orderAttr: "updatedAt" },
  },
  {
    callback: getLeads,
    label: "Par membre ayant envoyé le lead (A-Z)",
    value: "asc",
    params: {
      page: 1,
      orderBy: "ASC",
      orderAttr: "send.user.profile.lastName",
    },
  },
  {
    callback: getLeads,
    label: "Par membre ayant envoyé le lead (Z-A)",
    value: "desc",
    params: {
      page: 1,
      orderBy: "DESC",
      orderAttr: "send.user.profile.lastName",
    },
  },
];
export const leadsActions: Array<any> = [
  {
    label: "Du plus récent au plus ancien",
    value: "desc",
    params: { page: 1, orderBy: "DESC" },
  },
  {
    label: "Du plus ancien au plus récent",
    value: "asc",
    params: { page: 1, orderBy: "ASC" },
  },
  {
    label: "Par membre ayant envoyé le lead (A-Z)",
    value: "asc",
    params: {
      page: 1,
      orderBy: "ASC",
      orderAttr: "send.user.profile.lastName",
    },
  },
  {
    label: "Par membre ayant envoyé le lead (Z-A)",
    value: "desc",
    params: {
      page: 1,
      orderBy: "DESC",
      orderAttr: "send.user.profile.lastName",
    },
  },
];

export const headerArr: Array<{ label: string; href: string }> = [
  {
    label: "Concept des clubs",
    href: "/concept-des-clubs",
  },
  {
    label: "Rejoindre un club",
    href: "/rejoindre-un-club",
  },
  {
    label: "Créer un club",
    href: "/creer-un-club",
  },
  {
    label: "Témoignages",
    href: "/temoignages",
  },
  {
    label: "Dynabuy",
    href: "/dynabuy",
  },
];

export const getNavLinks = (slug: string): Array<NavLinks> => {
  return [
    { href: `/mes-clubs/${slug}`, label: "Accueil", icon: "home-smile" },
    { href: `/mes-clubs/${slug}/membres`, label: "Membres", icon: "members" },
    { href: `/mes-clubs/${slug}/leads`, label: "Leads", icon: "lead" },
    {
      href: `/mes-clubs/${slug}/invitations`,
      label: "Invitations",
      icon: "invitation",
    },
    {
      href: `/mes-clubs/${slug}/adhesions`,
      label: "Adhésions",
      icon: "adhesion",
    },
    //{/*TODO version finale, menu supplémentaire*/}
    // { href: "", label: "FRANCE", icon: "france" },
    // { href: "", label: "Agenda", icon: "agenda" },
    // { href: "", label: "Documents", icon: "documents" },
  ];
};
